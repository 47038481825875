import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  max-width: ${({ width }) => (width ? width : 500)}px;
  max-height: ${({ height }) => (height ? height : 500)}px;

  > img {
    filter: 'gray'; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all 0.3s ease-in-out;
    display: block;
    width: 100%;
    min-width: 330px;
    max-width: ${({ width }) => (width ? `${width}px` : '100%')};
    max-height: ${({ height }) => (height ? `${height}px` : '100%')};
    object-fit: ${({ fit }) => (fit ? fit : 'cover')};
    object-position: ${({ position }) => (position ? position : 'center')};
  }

  img:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }
`

export const MentorPicture = ({
  src = '',
  alt = 'image',
  width,
  height,
  position,
  fit,
}) => (
  <Container width={width} height={height} position={position} fit={fit}>
    <img src={src} alt={alt} height={height} width={width} />
  </Container>
)
