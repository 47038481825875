import * as React from 'react'
import styled, { css } from 'styled-components'

// COMPONENTS
import { Paragraph } from '../styled/typography'
import Fb from 'svgs/facebook.svg'
import LinkedIn from 'svgs/linkedin.svg'
import Youtube from 'svgs/youtube.svg'
import Instagram from 'svgs/instagram.svg'
import Twitter from 'svgs/twitter.svg'
import { getTranslation } from '../../data/dictionary'
import { MentorPicture } from '../styled/mentorPicture'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > h5 {
    margin: calc(var(--spacing) * 2) 0 0 0;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  height: 100%;
  max-height: calc(var(--spacing) * 110);
  margin-bottom: calc(var(--spacing) * 2);
  > div {
    height: 100%;
  }
`

const Name = styled.h5`
  font-weight: normal;
`

const Content = styled(Paragraph)`
  margin: calc(var(--spacing) * 2) 0 0 0;
`
const Footer = styled.div`
  margin: calc(var(--spacing) * 2) 0 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    :not(:last-child) {
      margin-right: calc(var(--spacing) * 3);
    }
  }
  p {
    margin-right: calc(var(--spacing) * 3);
    flex-shrink: 0;
  }
  svg {
    path {
      transition: all 0.3s;
      fill: var(--primary);
    }

    &:hover {
      path {
        fill: var(--secondary);
      }
    }
  }
`

const ProfileCard = ({
  name,
  imageUrl,
  role,
  content,
  social = {},
  bigRole,
}) => {
  const { twitter, linkedin, instagram, youtube, facebook } = social
  return (
    <Container>
      <ImageContainer>
        <MentorPicture
          src={imageUrl}
          alt="profile-picture"
          placeholder="tracedSVG"
          objectFit="cover"
          height={420}
          width={330}
        />
      </ImageContainer>

      <Name>{name}</Name>
      {bigRole && <h5>{bigRole}</h5>}
      <Paragraph
        css={css`
          font-weight: 500;
          margin: calc(var(--spacing) * 2) 0 0 0;
        `}
      >
        {role}
      </Paragraph>

      <Content>{content}</Content>
      {Object.keys(social).length > 0 && (
        <Footer>
          <Paragraph>{getTranslation('Connect to', 'Connect to')}</Paragraph>

          {linkedin && (
            <a href={String(linkedin)} target="_blank" rel="noreferrer">
              <LinkedIn />
            </a>
          )}
          {twitter && (
            <a href={String(twitter)} target="_blank" rel="noreferrer">
              <Twitter />
            </a>
          )}
          {facebook && (
            <a href={String(facebook)} target="_blank" rel="noreferrer">
              <Fb />
            </a>
          )}
          {youtube && (
            <a href={String(youtube)} target="_blank" rel="noreferrer">
              <Youtube />
            </a>
          )}
          {instagram && (
            <a href={String(instagram)} target="_blank" rel="noreferrer">
              <Instagram />
            </a>
          )}
        </Footer>
      )}
    </Container>
  )
}

export default ProfileCard
