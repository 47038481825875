import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import { FixedContainer } from 'components/styled/containers'
import ProfileCard from 'components/about/profileCard'

// ASSETS
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import { config } from '../../config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`
const StyledContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: flex-start;
  
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    max-width: 100%;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  justify-content: center;
  margin-top: calc(var(--spacing) * 10);

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    justify-content: start;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: calc(var(--spacing) * -2) calc(var(--spacing) * -2) 0
      calc(var(--spacing) * -2);
    > div {
      min-width: 70vw;
      flex: 0 0 auto;
      flex-shrink: 0;
      padding-bottom: calc(var(--spacing) * 10);
    }
  }

  > div {
    max-width: 23%;
    flex: 0 0 23%;
    justify-content: flex-start;
    flex-basis: 23%;
    margin: calc(var(--spacing) * 3);
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    > div {
      max-width: 30%;
      flex: 0 0 30%;
      flex-basis: 27%;
      margin: calc(var(--spacing) * 3);
    }
  }
`
const Heading = styled.div`
  margin-bottom: 0;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 8px;
    h2 {
      font-size: 2.4rem;
    }
  }
`

const ManagingTeam = ({ members }) => {
  return (
    <Container>
      <StyledContainer>
        <Heading>
          <ShadowText highlight>
            <h3>{getTranslation('Managing Team', 'Managing Team')}</h3>
          </ShadowText>
        </Heading>
        <CardContainer>
          {members.map(
            ({ name, position, content, social, imageUrl }, index) => (
              <ProfileCard
                key={index}
                name={name}
                role={position}
                content={content}
                social={social}
                imageUrl={`${config.CDN_BASE_URL}/${imageUrl}`}
              />
            ),
          )}
        </CardContainer>
      </StyledContainer>
    </Container>
  )
}

export default ManagingTeam
