import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import XIcon from 'svgs/x.svg'

const Container = styled.div`
  background-color: var(--primary-lighter);
  color: var(--primary);
  padding: var(--spacing) calc(var(--spacing) * 2);
  display: flex;
  align-items: center;
  border-radius: calc(var(--spacing) * 2);
`

const RightAction = styled.a`
  display: flex;
  align-items: center;
  padding-left: calc(var(--spacing) * 2);
  height: 100%;
  cursor: pointer;
`

const ClosablePill = ({ children, onClose }) => {
  return (
    <Container>
      {children}
      <RightAction role="button" onClick={onClose}>
        <XIcon />
      </RightAction>
    </Container>
  )
}

export default ClosablePill
